import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Layout from "../components/Layout";
import Features from "../components/Features";
import logo from "../img/1369.png";
const Carousel = require("react-responsive-carousel").Carousel;


export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  carouselImages
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`
      }}
    >
      <div
        style={{
          display: "flex",
          lineHeight: "1",
          justifyContent: "space-around",
          alignItems: "left",
          flexDirection: "column",
          maxWidth: "660px",
          position: "relative"
        }}
      >
        <div
          style={{
            opacity: 0.45,
            backgroundColor: "#FFF",
            width: "100%",
            height: "100%",
            filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=45)",
            position: "absolute",
            zIndex: 1
          }}
        />
        <img
          src={logo}
          alt="Sochena"
          className="central-logo"
        />
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            color: "#1E59A8",
            lineHeight: "1",
            padding: "0.25em",
            opacity: 1,
            zIndex: 100,
            margin: "1rem",
            textAlign: "center"
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    {console.log(carouselImages)}
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-6 is-centered" style={{ margin: "auto" }}>
                    <Carousel
                      showArrows={true}
                      infiniteLoop
                      useKeyboardArrows
                      autoPlay
                      showThumbs={false}
                      showStatus={false}
                    >
                      {carouselImages.map((img, i) => {
                        return (
                          <div key={i}>
                            <img
                              src={
                                !!img.image.image.childImageSharp
                                  ? img.image.image.childImageSharp.fluid.src
                                  : img.image.image
                              }
                              alt="carrusel"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
                {/* <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/products">
                      See all products
                    </Link>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest stories
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  }),
  carouselImages: PropTypes.array
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        carouselImages={frontmatter.carouselImages}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
        carouselImages {
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
